<template>
    <div id="app">
        <div class="language-switch-area">
            <b-dropdown class="language-switch" dropup :text="$i18n.locale.toUpperCase()">
                <b-dropdown-item
                    v-for="(lang, i) in langs"
                    :key="`Lang${i}`"
                    :value="lang"
                    @click="$i18n.locale = lang">
                    {{ lang.toUpperCase() }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
      <router-view></router-view>
    </div>
</template>

<style lang="sass">
   @import 'src/style/main.sass'
</style>

<script>
import auth from '../auth'
import i18n from '../i18n'

export default {
    data() {
        return {
            user: auth.user,
            langs: i18n.availableLocales
        }
    },
    mounted() {
        const browserLang = navigator.language || navigator.userLanguage;
        const lang = localStorage.getItem('language');
        i18n.locale = lang ? lang : browserLang;
        this.$watch(
            "$i18n.locale",
            (newLocale, oldLocale) => {
                if (newLocale === oldLocale) {
                    return
                }
                localStorage.setItem('language', newLocale)
            },
            { immediate: true }
        )
    },
    methods: {
        logout() {
            auth.logout()
        }
    }
}
</script>

