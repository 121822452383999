import gql from 'graphql-tag';
import moment from 'moment';

const editSessionMutation = gql`
mutation ($dateTime: String!, $reference: String!, $category: String!, $session: String!){
  updateSession(session: $session, newSession: {dateTime: $dateTime, reference: $reference, category: $category}) {
    id
  }
}
`;

const editAttendantMutation = gql`
mutation ($company: String!, $email: String!, $firstname: String!, $lastname: String!, $attendant: String!){
  updateAttendant(attendant: $attendant, newAttendant: {company: $company, email: $email, firstname: $firstname, lastname: $lastname}) {
    id
  }
}
`;

const categoryQuery = gql`
  query category {
    allCategories {
      id
      name
    }
  }
`;

export default {
    data() {
        return {
            configDate: {
                format: 'DD.MM.YYYY',
                defaultDate: this.aDate
            },
            configTime: {
                format: 'HH:mm',
                defaultDate: this.aTime
            },
            allCategories: [],
            error: '',
            attError: '',
            updated: false,
            dateTime: new Date(this.$psTeamStore.state.editSession.dateTime),
            aTime: new Date(this.$psTeamStore.state.editSession.dateTime),
            aDate: new Date(this.$psTeamStore.state.editSession.dateTime)
        }
    },
    computed: {
      update() {
          return this.$psTeamStore.state.editSession;
      },
      mainAtt() {
          var attendant;
          this.update.attendants.forEach(function(att) {
              if (att.main) {
                  attendant = att;
              }
          });
          return attendant;
      }
    },
    apollo: {
        allCategories: {
            query: categoryQuery,
        },
    },
    methods: {
        validateBeforeSubmit() {
            var timeString = this.aTime instanceof Date ? this.aTime.toLocaleString('de-DE', { hour: 'numeric', minute: 'numeric' }) : this.aTime;
            var dateString = this.aDate instanceof Date ? this.aDate.toLocaleString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' }) : this.aDate;
            var dateSplit = dateString.toString().split(".");
            var timeSplit = timeString.toString().split(":");
            this.dateTime = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0], timeSplit[0], timeSplit[1]);

            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.submit();
                } else {
                    this.error = 'Bitte alle Felder ausfüllen!';
                }
            });
        },
        submit() {
            if (this.dateTime > new Date()) {
                this.$apollo.mutate({
                    mutation: editSessionMutation,
                    variables: {
                        dateTime: this.dateTime,
                        reference: this.update.reference,
                        category: this.update.category.id,
                        session: this.$psTeamStore.state.editSession.id
                    },
                })
                .then(({ data }) => {
                    this.error = '';
                    this.changeAttendant();
                    this.updated = true;
                }).catch((error) => {
                    this.error = error.message;
                });
            } else {
                this.error = 'Das Datum muss in der Zukunft liegen!';
            }
        },
        changeAttendant() {
            var that = this;
            this.update.attendants.forEach(function(attendant){
                if(attendant.main){
                    that.$apollo.mutate({
                        mutation: editAttendantMutation,
                        variables: {
                            company: that.mainAtt.company,
                            firstname: that.mainAtt.firstname,
                            lastname: that.mainAtt.lastname,
                            email: that.mainAtt.email,
                            attendant: attendant.id
                        },
                    })
                    .then(({ data }) => {
                        console.log(attendant);
                        that.attError = '';
                        that.updated = true;
                    }).catch((error) => {
                        that.attError = error.message;
                    });
                }
            });

        }
    }
}
