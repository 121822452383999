<template>
	<div class="main-content">
		<Assignments></Assignments>
	</div>
</template>

<script>
import Assignments from '../components/Assignments.vue';

export default {
    components: {
        Assignments
	}
}
</script>
