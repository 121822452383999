import Vue from 'vue'
import Router from 'vue-router'
import Content from '../components/Content.vue';
import Login from '../components/Login.vue';
import Signup from '../components/Signup.vue';
import RequestReset from '../components/RequestReset.vue';
import ResetPw from '../components/Reset.vue';

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            component: Content,
            meta: {requiresAuth: true}
        },
        {
            path: '/login',
            component: Login
        },
        {
            path: '/signup',
            component: Signup
        },
        {
            path: '/requestreset',
            component: RequestReset
        },
        {
            path: '/reset',
            component: ResetPw
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        var jwt = localStorage.getItem('JWT');
        if (jwt) {
            next()
        }
        else {
            next('/login')
        }
    }
    else {
        next()
    }
})

export default router;
