import auth from '../auth'
export default {
    data() {
        return {
            credentials: {
                username: '',
                password: ''
            },
            error: ''
        }
    },
    methods: {
        submit() {
            var credentials = {
                username: this.credentials.username,
                password: this.credentials.password
            }
            auth.login_or_signup('signup', this, credentials, 'secretquote')
        }
    }
}
