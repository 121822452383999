import gql from 'graphql-tag';

const editAssetMutation = gql`
mutation ($asset: String!, $name: String!){
  updateAsset (asset: $asset, newAsset: { name: $name } ) {
    id
  }
}
`;

export default {
    data() {
        return {
            error: '',
            name: this.$psTeamStore.state.selectedAsset.name,
            newName: this.$psTeamStore.state.selectedAsset.name,
            selectedS: this.$psTeamStore.state.selectedSession,
            nameExists: false,
            fieldError: false
        }
    },
    computed: {
        asset() {
            return this.$psTeamStore.state.selectedAsset;
        },
        thumbData() {
            return this.$psTeamStore.state.thumbData;
        }
    },
    mounted() {
        this.assetImage();
    },
    methods: {
        assetImage() {
            const token = localStorage.getItem('JWT');
            var options = {
                url: BACKEND_URI + "/asset/"  + this.asset.id + "/file",
                method: 'GET',
                responseType:'blob',
                headers:
                    {
                        Authorization: token ? `Bearer ${token}` : null
                    }
            }
            this.$http(options).then((response) => {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(response.data);
                document.getElementById("thumbnailImg").src = imageUrl;
            });
        },
        validateBeforeSubmit() {
            var that = this;
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.submit();
                } else {
                    this.fieldError = true;
                    setTimeout(function(){
                        that.fieldError = false;
                    }, 2000)
                }
            });
        },
        submit() {
            var that = this;
            var assetName = false;
            if(this.$psTeamStore.state.selectedAssetList.assets !== undefined) {
                this.$psTeamStore.state.selectedAssetList.assets.forEach(function(asset) {
                    if (asset.name.toLowerCase() === that.newName.toLowerCase()) {
                        return assetName = true;
                    }
                });
            }
            if (assetName) {
                that.nameExists = true;
                setTimeout(function(){
                    that.nameExists = false;
                }, 5000)
            }
            else if (this.newName === "" || !this.newName.replace(/\s/g, '').length ) {
                this.fieldError = true;
                setTimeout(function(){
                    that.fieldError = false;
                }, 2000)
            }
            else {
                this.$apollo.mutate({
                    mutation: editAssetMutation,
                    variables: {
                        asset: this.$psTeamStore.state.selectedAsset.id,
                        name: this.newName
                    },
                })
                    .then(({data}) => {
                        this.error = '';
                        this.name = this.newName;
                    }).catch((error) => {
                    if (error) {
                        that.nameExists = true;
                        setTimeout(function(){
                            that.nameExists = false;
                        }, 5000)
                    }
                });
            }
        }
    }
}
