import gql from 'graphql-tag';
import Footer from '../components/Footer.vue';
// GraphQL query

const loginMutation = gql`
    mutation ($username: String!, $password: String!) {
        loginWeb(credentials: {username: $username, password: $password}) {
            token
        }
    }
`;
export default {
    components: {
        Footer
    },
    data() {
        return {
            credentials: {
                username: '',
                password: ''
            },
            error: false
        }
    },
    methods: {
        submit() {
            this.$apollo.mutate({
                mutation: loginMutation,
                variables: {
                    username: this.credentials.username,
                    password: this.credentials.password
                },
            })
            .then(({ data }) => {
                this.error = '';
                this.$psTeamStore.state.userToken = data.loginWeb.token;
                localStorage.setItem('JWT', data.loginWeb.token);
                this.$router.push("/");
            }).catch((error) => {
                this.error = true;
            });
        }
    }
}
