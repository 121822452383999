import gql from 'graphql-tag';
import ProfileModal from '../components/modals/ProfileModal.vue';
import Modal from '../components/modals/Modal.vue'

const userQuery = gql`
	  query user {
	  	me {
	  		username
	  	}
	  }
	`;
export default {
    components: {
        ProfileModal,
        Modal
    },
    data() {
        return {
            me: [],
            ProfileModal: false,
        }
    },
    apollo: {
        me: {
            query: userQuery,
        },
    },
    methods: {
        logout() {
            this.$psTeamStore.state.userToken = null;
            localStorage.removeItem('JWT');
            this.$router.push("/login");
            location.reload();
        }
    }
}
