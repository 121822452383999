import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        count: "0",
        userToken: '',
        activeRecording: '',
        editSession: [],
        clickedAssignment: null,
        language: null,
        inviteTo: [],
        selectedSession: [
            {
                attendants: [],
                assetLists: [],
            }
        ],
        selectedAssetList: [],
        editAssetList: [],
        selectedAsset: [],
        thumbData: [],
        attendantDisconnected: '',
        attendantConnected: '',
        sessionToEnd: [],
        sessionToDelete: []
    },
    plugins: [createPersistedState()],
    mutations: {
        increment (state) {
            state.count++
        }, user (token) {
            state.userToken = token
        },

    }
})
