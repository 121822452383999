import {ApolloClient, createNetworkInterface} from 'apollo-client'
import VueApollo from 'vue-apollo'
import Vue from 'vue';
import router from '../router'

Vue.use(VueApollo);

const networkInterface = createNetworkInterface({
    uri: BACKEND_URI + "/graphql",
});

const logErrors = {
    applyAfterware({ response }, next) {
        if (!response.ok) {
            response.clone().text().then(bodyText => {
                console.error(`Network Error: ${response.status} (${response.statusText}) - ${bodyText}`);
                next();
            });
        } else {
            response.clone().json().then(({ errors }) => {
                if (errors) {
                    console.error('GraphQL ERR:', errors.map(e => e.message));
                    errors.forEach(function(error) {
                        if (error.message === 'NOT_AUTHORIZED') {
                            localStorage.removeItem('JWT');
                            router.push("/login");
                            location.reload();
                        }
                    });
                }
                next();
            });
        }
    },
};

networkInterface.useAfter([logErrors]);

networkInterface.use([{
    applyMiddleware(req, next) {
        if (!req.options.headers) {
            req.options.headers = {};
        }
        const token = localStorage.getItem('JWT');
        req.options.headers.authorization = token ? `Bearer ${token}` : null;
        next();
    }
}]);

const apolloClient = new ApolloClient({
    networkInterface,
});

export default new VueApollo({
    defaultClient: apolloClient,
});
