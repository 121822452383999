<template>
    <div>
        <div class="modal-heading">
            <h3>{{ $t('send.invite') }}</h3>
            <p>{{ $t('invite.message') }}</p>
            <p v-for="attendant in invitation.attendants" v-bind:key="attendant.id">
              <b>{{ attendant.email }}</b>
            </p>
            <br>

            <label for="inputGroupSelect01">{{ $t('mail.language') }}*</label>
            <select v-model="selectedLocal" class="custom-select" id="inputGroupSelect01" v-if="invitationLocales.length > 1">
              <option v-for="locale in invitationLocales" v-bind:key="locale" :value="locale.toUpperCase()">
                {{locale}}
              </option>
            </select>
            <select v-model="selectedLocal" class="custom-select" id="inputGroupSelect01" v-else>
              <option v-for="locale in invitationLocales" v-bind:key="locale" v-bind:value="locale.toUpperCase()" class="text-left" selected>
                {{locale}}
              </option>
            </select>
            <br>
            <br>

            <button v-if="!sending" :disabled="!selectedLocal" class="btn modal-btn col-xs-12" @click="sendEmail('link_and_code')">
                {{ $t('send.invite') }}
            </button>

            <div v-if="sent" class="col-xs-12 success-reset">{{ $t('invite.sent') }}</div>
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import underscore from 'underscore';
import i18n from '../../i18n'

const invitationLocalesQuery = gql`
    query {
        invitationLocales
    }
`;

const sendInvitationMutation = gql`
    mutation ($session: String!, $type: String!, $locale: String) {
        sendInvitation(invitation: { session: $session, type: $type, locale: $locale })
    }
`;

export default {
    data() {
        return {
            sent: false,
            sending: false,
            selectedLocal: null
        }
    },
    computed: {
      invitation() {
          return this.$psTeamStore.state.inviteTo;
      },

    },
    apollo: {
        invitationLocales: {
            query: invitationLocalesQuery,
        },
    },
    methods: {
        isSaved() {
            this.saved = true;
            setTimeout(()=>{
                this.saved = false
            },2000);
        },
        sendEmail(type) {
            if (!this.selectedLocal) {
              this.sending = false
              return null;
            }

            this.sending = true;
            this.$apollo.mutate({
                mutation: sendInvitationMutation,
                variables: {
                    session: this.$psTeamStore.state.inviteTo.id,
                    type: type,
                    locale: this.selectedLocal
                },
            })
            .then(({data}) => {
                this.error = '';
                this.sent = true;
                this.sending = false;
                setTimeout(() => {
                    this.sent = false
                }, 2000);
            })
            .catch((error) => {
                this.error = error.message;
            });
        }
    },
    mounted: function() {
        if (this.invitationLocales.includes(i18n.locale.toUpperCase())) {
            this.selectedLocal = i18n.locale.toUpperCase()
        } else if (this.invitationLocales.length === 1) {
            this.selectedLocal = this.invitationLocales.toString().toUpperCase();
        }
    }
}
</script>
