import gql from 'graphql-tag';
// GraphQL query

// TODO
// Fetch token from URL, use URL token to verify change request

const resetMutation = gql`
    mutation ($email: String!, $password: String!, $token: String!) {
        resetPassword (passwordReset: {email: $email, password: $password, token: $token})
    }
`;
export default {
    data() {
        return {
            reset: {
                email: this.$route.query.email,
                token: this.$route.query.token,
                password: '',
                verifypassword: ''
            },
            error: '',
            saved: false,
            pwerror: 'Die Passwörter stimmen nicht überein.'
        }
    },
    methods: {
        submit() {
            if (this.reset.password === this.reset.verifypassword) {
                this.$apollo.mutate({
                    mutation: resetMutation,
                    variables: {
                        email: this.reset.email,
                        password: this.reset.password,
                        token: this.reset.token
                    },
                })
                    .then(({ data }) => {
                        this.error = '';
                        this.saved = true;
                    }).catch((error) => {
                    this.error = error.message;
                });
            }
            else {
                this.error = this.pwerror;
            }
        }
    }
}
