import gql from 'graphql-tag';
import Footer from '../components/Footer.vue';
import ContentHolder from '../components/ContentHolder.vue'

// GraphQL query

const refreshMutation = gql`
mutation {
    refreshToken{
        token
    }
}
`;
export default {
    components: {
        Footer,
        ContentHolder
    },
    data() {
        return {}
    },
    methods: {
        refresh() {
            this.$apollo.mutate({
                mutation: refreshMutation,
            })
                .then(({ data }) => {
                    this.error = '';
                    this.$psTeamStore.state.userToken = data.refreshToken.token;
                    localStorage.setItem('JWT', data.refreshToken.token);
                }).catch((error) => {
                this.error = error.message;
            });
        },
    },
    mounted() {
        this.refresh();
        setInterval(function(){
            this.refresh();
        }.bind(this), 1800000);
    },
}
