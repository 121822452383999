import gql from 'graphql-tag';
import Footer from '../components/Footer.vue';
// GraphQL query

const requesResetMutation = gql`
  mutation ($email: String!, $lastname: String!) {
      requestPasswordReset (passwordResetRequest: {email: $email, lastname: $lastname})
  }
`;
export default {
    components: {
        Footer
    },
    data() {
        return {
            credentials: {
                email: '',
                lastname: ''
            },
            error: '',
            saved: false
        }
    },
    methods: {
        submit() {
            this.$apollo.mutate({
                mutation: requesResetMutation,
                variables: {
                    email: this.credentials.email,
                    lastname: this.credentials.lastname
                },
            })
                .then(({ data }) => {
                    this.error = '';
                    this.saved = true;
                }).catch((error) => {
                this.error = error.message;
            });
        }
    }
}
