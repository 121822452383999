export default {
    data() {
        return {
            object: {
                firstname:'Max',
                lastname:'Mustermann',
                email:'test@testing.com'
            },
            isEdit: false,
            saved: false
        }
    },
    methods: {
        isSaved: function(){
            this.saved = true;
            setTimeout(()=>{
                this.saved = false
            },2000);
        }
    }
}
