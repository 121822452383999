import gql from 'graphql-tag';
import underscore from 'underscore';
import moment from 'moment'
import Header from '../components/Header.vue';
import NewAssignmentModal from '../components/modals/NewAssignmentModal.vue';
import EditAssignmentModal from '../components/modals/EditAssignmentModal.vue';
import EditAssetModal from '../components/modals/EditAssetModal.vue';
import CodeModal from '../components/modals/CodeModal.vue';
import EndSessionModal from '../components/modals/EndSessionModal.vue';
import DeleteSessionModal from '../components/modals/DeleteSessionModal.vue';
import Modal from '../components/modals/Modal.vue';
import i18n from '../i18n'
import iconSortReverted from '../components/icons/ic_sort_reverted.vue';
import iconSort from '../components/icons/ic_sort.vue';
import iconCross from '../components/icons/ic_cross.vue';

const sessionQuery = gql`
  query session {
  	allSessions {
	  	id
	    dateTime
	    reference
	    state
	    category {
	        id
	    	name
	    }
	    attendants {
	    	id
	    	firstname
	    	lastname
	    	company
	    	email
	    	main
	    	invitations {
	    	    type
	    	}
	    }
	    assetLists {
	    	id
	    	name
	    	assets {
	    	    id
	    		name
	    		file
	    		type
	    		createdAt
	    		state
	    	}
	    }
  	}
  }
`;

const invitationLocalesQuery = gql`
    query {
        invitationLocales
    }
`;

const startCallMutation = gql`
    mutation ($session: String!) {
        createTwilioSessionForWeb (session: $session) {
        	token
        }
    }
`;

const removeAttMutation = gql`
    mutation ($attendant: String!) {
        deleteAttendant (attendant: $attendant)
    }
`;

const addAttMutation = gql`
    mutation (
        $session: String!,
        $firstname: String!,
        $lastname: String!,
        $company: String!,
        $email: String!,
        $invitationType: String!,
        $invitationLocale: String
    ) {
        addAttendant (
            session: $session,
            attendant: {
                firstname: $firstname,
                lastname: $lastname,
                company: $company,
                email: $email,
                invitationType: $invitationType,
                invitationLocale: $invitationLocale
            }
        ) {
        	id
        }
    }
`;

const addAssetListMutation = gql`
    mutation ($session: String!, $name: String!) {
        addAssetList (assetList: { session: $session, name: $name }) {
        	id
        }
    }
`;

const editAssetListMutation = gql`
    mutation ($assetList: String!, $session: String!, $name: String!) {
        updateAssetList (assetList: $assetList, newAssetList: { session: $session, name: $name }) {
        	id
        }
    }
`;

const addAssetMutation = gql`
    mutation ($assetList: String!, $name: String!, $type: String!) {
        addAsset (asset: { assetList: $assetList, name: $name, type: $type }) {
        	id
        	type
        }
    }
`;

const stopAssetMutation = gql`
    mutation ($asset: String!) {
        stopAsset (asset: $asset)
    }
`;

const deleteSessionMutation = gql`
    mutation ($session: String!) {
        deleteSession (session: $session)
    }
`;

const marksAsDoneMutation = gql`
    mutation ($session: String!) {
        markSessionAsDone (session: $session)
    }
`;

const Video = require('twilio-video');
const {createLocalVideoTrack} = require('twilio-video');
const {removeLocalVideoTrack} = require('twilio-video');

export default {
    components: {
        Header,
        NewAssignmentModal,
        EditAssignmentModal,
        EditAssetModal,
        CodeModal,
        EndSessionModal,
        DeleteSessionModal,
        Modal,
        iconSortReverted,
        iconSort,
        iconCross
    },
    data() {
        return {
            twilioToken: '',
            addAtt: {
                company: '',
                firstname: '',
                lastname: '',
                email: ''
            },
            newAssetList: '',
            editList: '',
            assets: [],
            newAssignmentModal: false,
            editAssignmentModal: false,
            editAssetModal: false,
            ProfileModal: false,
            CodeModal: false,
            endSessionModal: false,
            deleteSessionModal: false,
            ListView: true,
            newAsset: false,
            editAsset: false,
            newAtt: false,
            sent: false,
            error: '',
            callClosed: false,
            callStarted: false,
            downloadSession: [],
            downloadStarted: false,
            downloadError: false,
            recording: false,
            nameExists: false,
            userConnected: false,
            thumbData: [],
            activeSession: localStorage.getItem("activeSession"),
            mailSent: [],
            codeSent: [],
            userLeft: false,
            userJoined: false,
            countdown: 90,
            downloadTimer: '',
            confirmRemoval: false,
            attToRemove: '',
            connectedAttendants: [],
            selectedLocal: '',
            selectedDates: {
              from: null,
              to: null
            },
            selectedReferences: [],
            selectedCategories: [],
            selectedStatus: [],
            orderedBy: {
                reversed: false,
                value: 'time'
            },
        }
    },
    computed: {
        selectedS() {
          return this.$psTeamStore.state.selectedSession;
        },
        sortedSessions() {
          let sorted = _.sortBy(this.allSessions, 'dateTime').reverse();

          if (this.selectedReferences !== undefined && this.selectedReferences.length > 0) {
            sorted = sorted.filter((item) => {
              return this.selectedReferences.includes(item['reference'])
            })
          }

          if (this.selectedCategories !== undefined && this.selectedCategories.length > 0) {
            sorted = sorted.filter((item) => {
              return this.selectedCategories.includes(item['category']['name'])
            })
          }

          if (this.selectedStatus !== undefined && this.selectedStatus.length > 0) {
            sorted = sorted.filter((item) => {
              return this.selectedStatus.includes(item['state'])
            })
          }

          if (this.selectedDates.from) {
            sorted = sorted.filter((item) => {
              return item['dateTime'] >= this.selectedDates.from
            })
          }

          if (this.selectedDates.to) {
            sorted = sorted.filter((item) => {
              return item['dateTime'] <= this.selectedDates.to
            })
          }

          switch(this.orderedBy.value) {
              case 'time':
                  break;
              case 'reference':
                  sorted = _.sortBy(sorted, session => session.reference);
                  break;
              case 'category':
                  sorted = _.sortBy(sorted, session => session.category.name);
                  break;
              case 'status':
                  sorted = _.sortBy(sorted, session => session.state);
                  break;
              default:
                  return sorted;
          }

          if (this.orderedBy.reversed) {
              return sorted.reverse();
          }

          return sorted;
        },
        mappedReferences() {
          if (this.allSessions !== null) {
            const mapped = this.allSessions.map(function(item) {
              return item['reference'];
            });
            return mapped.filter((value, index, self) => {
              return self.indexOf(value) === index;
            }).sort();
          }
          return null;
        },
        mappedCategories() {
          if (this.allSessions !== null) {
            const mapped = this.allSessions.map(function(item) {
              return item['category']['name'];
            });
            return mapped.filter((value, index, self) => {
              return self.indexOf(value) === index;
            }).sort();
          }
          return null;
        },
        mappedStatus() {
          if (this.allSessions !== null) {
            const mapped = this.allSessions.map(function(item) {
              return item['state'];
            });
            return mapped.filter((value, index, self) => {
              return self.indexOf(value) === index;
            });
          }
          return null;
        },
        sortedAssetList() {
            return _.sortBy(this.selectedS.assetLists, 'id').map(list => {
                return Object.assign(list, {
                    assets: _.sortBy(list.assets, 'createdAt')
                });
            });
        },
        sessionZIndex() {
            return _.sortBy(this.allSessions, 'dateTime')
        },
        attendantConnected() {
            return this.$psTeamStore.state.attendantConnected
        },
        attendantDisconnected() {
            return this.$psTeamStore.state.attendantDisconnected
        },
        sessionToMarkAsDone() {
            return this.$psTeamStore.state.sessionToEnd
        },
        sessionToRemove() {
            return this.$psTeamStore.state.sessionToDelete
        },
        currentLocal() {
            return i18n.locale.toUpperCase()
        }
    },
    updated() {
        this.setEventListenerOnDropdownButton();
    },
    apollo: {
        allSessions: {
            query: sessionQuery,
        },
        invitationLocales: {
            query: invitationLocalesQuery,
        }
    },
    mounted() {
        this.updateList();
    },
    methods: {
        updateList() {
            setTimeout(() => {
                this.checkFiles();
                this.invitationSent();
            }, 1000);
            setInterval(function () {
                this.checkFiles();
                this.invitationSent();
                this.$apollo.queries.allSessions.refetch();
            }.bind(this), 15000);
        },
        setEventListenerOnDropdownButton() {
            let dropdownBtns = document.getElementsByClassName("dropdown-assignment");
            let moveFunc = this.moveAssignmentRowToFront;
            for (let i = 0; i < dropdownBtns.length; i++) {
                dropdownBtns[i].getElementsByTagName('button')[0].addEventListener("click", function(event) { moveFunc(event); });
            }
        },
        checkFiles() {
            var downloadS = [];
            downloadS.lenght = 0;
            if (this.allSessions !== null) {
                this.allSessions.forEach(function (session) {
                    session.assetLists.forEach(function (list) {
                        list.assets.forEach(function (asset) {
                            if (asset.state === "done") {
                                downloadS.push(session.id)
                            } else {
                                if (downloadS.indexOf(session.id)) {
                                    let sId = downloadS.indexOf(session.id)
                                    downloadS.splice(sId, 0)
                                }
                            }
                        });
                    });
                });
            }
            downloadS = downloadS.filter(function(item, index, inputArray) {
                return inputArray.indexOf(item) == index;
            });
            this.downloadSession = downloadS;
        },
        invitationSent() {
            var inviteMail = [];
            var inviteCode = [];
            if (this.allSessions !== null) {
                this.allSessions.forEach(function (session) {
                    session.attendants.forEach(function (attendant) {
                        if (attendant.main) {
                            if(attendant.invitations !== null ) {
                                attendant.invitations.forEach(function (invite) {
                                    switch (invite.type) {
                                        case "link":
                                            inviteMail.push(session.id)
                                            break;
                                        case "code":
                                            inviteCode.push(session.id)
                                            break;
                                        case "link_and_code":
                                            inviteMail.push(session.id)
                                            inviteCode.push(session.id)
                                    }
                                })
                            }
                        }
                    })
                })
            }
            inviteMail = inviteMail.filter(function(item, index, inputArray) {
                return inputArray.indexOf(item) == index;
            });
            this.mailSent = inviteMail;
            inviteCode = inviteCode.filter(function(item, index, inputArray) {
                return inputArray.indexOf(item) == index;
            });
            this.codeSent = inviteCode;
        },
        validateBeforeSubmit() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.addAttendant();
                } else {
                    this.error = this.$t('fill.blanks');
                }
            });
        },
        newAssignmentModalClose() {
            this.newAssignmentModal = false;
            this.$apollo.queries.allSessions.refetch();
        },
        editAssetModalClose() {
            this.editAssetModal = false;
            this.updateData()
        },
        openEditAssignmentModal(allSessions) {
            this.$psTeamStore.state.editSession = allSessions;
            this.editAssignmentModal = true;
        },
        codeModalClose() {
            this.CodeModal = false;
            this.invitationSent()
        },
        markAsDoneCheck(session){
            this.$psTeamStore.state.sessionToEnd = session;
            this.endSessionModal = true;
        },
        removeSessionCheck(session){
            this.$psTeamStore.state.sessionToDelete = session;
            this.deleteSessionModal = true;
        },
        moveAssignmentRowToFront(event) {
            if (this.$psTeamStore.state.clickedAssignment !== null) {
                this.$psTeamStore.state.clickedAssignment.classList.remove("front-drop-down");
            }

            let el = event.target;
            let sel = ".assignment-list-row-container";

            while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
            this.$psTeamStore.state.clickedAssignment = el;
            this.$psTeamStore.state.clickedAssignment.className += " front-drop-down";
        },
        editAssignmentModalClose() {
            this.editAssignmentModal = false;
            this.$apollo.queries.allSessions.refetch();
        },
        remove (array, element) {
            return array.filter(e => e !== element)
        },
        startCall(allSessions) {
            this.$apollo.mutate({
                mutation: startCallMutation,
                variables: {
                    session: allSessions.id
                },
            })
                .then(({data}) => {
                    localStorage.setItem("activeSession", allSessions.id)
                    this.error = '';
                    var that = this;
                    this.$psTeamStore.state.selectedSession = allSessions;
                    this.getAssets();
                    this.getStorage();
                    this.ListView = false;
                    this.twilioToken = data.createTwilioSessionForWeb.token;
                    var connectOptions = {
                        name: '',
                        video: false,
                        audio: true,// Dont want to use localTrack
                    }
                    Video.connect(this.twilioToken, connectOptions).then(room => {
                        room.participants.forEach(participantConnected);
                        room.on('participantConnected', function (participant) {
                            var attendant = JSON.parse(participant.identity);
                            that.$psTeamStore.state.attendantConnected = attendant.name
                            that.connectedAttendants.push(attendant.name)
                            that.userJoined = true
                            setTimeout(function (){
                                that.userJoined = false
                            }, 5000)
                            var mainpart = participant.identity;
                            var part = JSON.parse(mainpart);
                            const div = document.getElementById('remote-media-div');

                            if (part.main === true) {
                                that.userConnected = true;

                                participant.on('trackSubscribed', track => trackSubscribed(div, track));
                                participant.on('trackUnsubscribed', track => trackUnsubscribed(track));
                                participant.tracks.forEach(publication => {
                                    if (publication.isSubscribed) {
                                        trackSubscribed(div, publication.track);
                                    }
                                });
                            } else if (part.main !== true) {
                                participant.on('trackSubscribed', track => {
                                    if (track.kind == 'audio') {
                                        trackSubscribed(div, track)
                                    }
                                })
                            } else {
                                console.log("im not called");
                            }
                        });

                        room.on('roomEnded', roomEnded);
                        room.on('participantDisconnected', participantDisconnected);
                        room.once('disconnected', error => room.participants.forEach(participantDisconnected));
                        room.on('disconnected', room => {
                            // Detach the local media elements
                            this.callClosed = true;
                            setInterval(function(){
                                this.endCall();
                            }.bind(this), 15000);

                            room.localParticipant.tracks.forEach(track => {
                                track.detach().forEach(element => {
                                    if (element.tagName.toLowerCase() === 'video') {
                                        element.remove()
                                    } else if (element.tagName.toLowerCase() === 'audio') {
                                        element.classList.add('existing')
                                    }
                                });
                            });
                        });
                    });

                    Video.createLocalAudioTrack().then(track => {
                        var localMediaContainer = document.getElementById('local-media-ctr');
                        localMediaContainer.appendChild(track.attach());
                    });

                    function participantConnected(participant) {
                        var attendant = JSON.parse(participant.identity);
                        that.$psTeamStore.state.attendantConnected = attendant.name
                        that.connectedAttendants.push(attendant.name)
                        that.userJoined = true
                        setTimeout(function (){
                            that.userJoined = false
                        }, 5000)
                        var mainpart = participant.identity;
                        var part = JSON.parse(mainpart);
                        const div = document.getElementById('remote-media-div');

                        if (part.main === true) {
                            that.userConnected = true;

                            participant.on('trackSubscribed', track => trackSubscribed(div, track));
                            participant.on('trackUnsubscribed',  track => trackUnsubscribed(track));
                            participant.tracks.forEach(publication => {
                                if (publication.isSubscribed) {
                                    trackSubscribed(div, publication.track);
                                }
                            });
                        } else {
                            participant.on('trackSubscribed', track => {
                                if (track.kind == 'audio') {
                                    trackSubscribed(div, track)
                                }
                            })
                        }
                    }

                    function participantDisconnected(participant) {
                        var attendant = JSON.parse(participant.identity);
                        that.$psTeamStore.state.attendantDisconnected = attendant.name
                        that.connectedAttendants = that.remove(that.connectedAttendants, attendant.name)
                        that.userLeft = true
                        setTimeout(function (){
                            that.userLeft = false
                        }, 5000)
                        var mainpart = participant.identity;
                        var part = JSON.parse(mainpart);
                        if (part.main === true) {
                            that.userConnected = false;
                            if (that.recording) {
                                that.stopRecording()
                            }
                        }
                    }

                    function roomEnded() {
                        console.log("Raum geschlossen");
                    }

                    function trackSubscribed(div, track) {
                        let existingAudio = document.getElementById('existing-audio');

                        if (track.kind === 'audio' && existingAudio) {
                            track.attach(existingAudio)
                        } else {
                            div.appendChild(track.attach());
                        }
                    }

                    function trackUnsubscribed(track) {
                        track.detach().forEach(element => {
                            if (element.tagName.toLowerCase() === 'video') {
                                element.remove()
                            } else if (element.tagName.toLowerCase() === 'audio') {
                                element.id = 'existing-audio'
                            }
                        });
                    }

                }).catch((error) => {
                this.error = error.message;
            });
        },
        sendInvite(allSessions) {
            this.CodeModal = true;
            this.$psTeamStore.state.inviteTo = allSessions;
        },
        addAttendant() {
            this.$apollo.mutate({
                mutation: addAttMutation,
                variables: {
                    session: this.selectedS.id,
                    company: this.addAtt.company,
                    firstname: this.addAtt.firstname,
                    lastname: this.addAtt.lastname,
                    email: this.addAtt.email,
                    invitationType: 'link_and_code',
                    invitationLocale: this.selectedLocal
                },
            })
                .then(({data}) => {
                    this.updateData();
                    this.error = '';
                    this.addAtt.company = '';
                    this.addAtt.firstname = '';
                    this.addAtt.lastname = '';
                    this.addAtt.email = '';
                    this.$forceUpdate();
                    this.newAtt = false;
                }).catch((error) => {
                this.error = error.message;
            });
        },
        openAddAttendantForm() {
            this.newAtt = true;
            this.selectedLocal = this.currentLocal;
        },
        cancelAddAttendantForm() {
            this.newAtt = false;
            this.selectedLocal = this.currentLocal;
        },
        removeAtt() {
            this.$apollo.mutate({
                mutation: removeAttMutation,
                variables: {
                    attendant: this.attToRemove.id
                },
            })
                .then(({data}) => {
                    this.error = '';
                    this.confirmRemoval = false
                    this.updateData();
                }).catch((error) => {
                this.error = error.message;
            });
        },
        requestConfirmation(attendants) {
            this.confirmRemoval = true
            this.attToRemove = attendants
        },
        addNewAssetList() {
            var that = this;
            var listName = false;
            this.selectedS.assetLists.forEach(function(list) {
                if (list.name === that.newAssetList) {
                    return listName = true;
                }
            });
            if (listName) {
                that.nameExists = true;
                setTimeout(function(){
                    that.nameExists = false;
                }, 5000)
            }
            else if (this.newAssetList === "" || !this.newAssetList.replace(/\s/g, '').length ) {
                this.error = this.$t('fill.blanks');
                setTimeout(function(){
                    that.error = '';
                }, 2000)
            }
            else {
                this.$apollo.mutate({
                    mutation: addAssetListMutation,
                    variables: {
                        session: this.selectedS.id,
                        name: this.newAssetList
                    },
                })
                    .then(({data}) => {
                        this.error = '';
                        this.newAsset = false;
                        this.newAssetList = '';
                        this.updateData();
                        var assetLists = data.addAssetList;
                        this.activeList(assetLists);
                        this.getAssets();
                    }).catch((error) => {
                    this.error = error.message;
                });
            }
        },
        editAssetList(assetList) {
            this.editAsset = true;
            this.$psTeamStore.state.editAssetList = assetList;
            this.editList = this.$psTeamStore.state.editAssetList.name
        },
        cancelEdit(){
            this.editAsset = false;
            this.getAssets();
        },
        updateAssetList() {
            var that = this;
            var listName = false;
            this.selectedS.assetLists.forEach(function(list) {
                if (list.name === that.editList) {
                    return listName = true;
                }
            });
            if (listName) {
                that.nameExists = true;
                setTimeout(function(){
                    that.nameExists = false;
                }, 5000)
            }
            else if (this.editList === "" || !this.editList.replace(/\s/g, '').length ) {
                this.error = this.$t('fill.blanks');
                setTimeout(function(){
                    that.error = '';
                }, 2000)
            }
            else {
                this.$apollo.mutate({
                    mutation: editAssetListMutation,
                    variables: {
                        assetList: this.$psTeamStore.state.editAssetList.id,
                        session: this.selectedS.id,
                        name: this.editList
                    },
                })
                    .then(({data}) => {
                        this.error = '';
                        this.editAsset = false;
                        this.editList = '';
                        this.updateData();
                        this.getAssets();
                    }).catch((error) => {
                    this.error = error.message;
                });
            }
        },
        activeList(assetLists) {
            this.$psTeamStore.state.selectedAssetList = assetLists;
            this.selectedList = true;
        },
        checkForList(image) {
            var listAmount = this.$psTeamStore.state.selectedSession.assetLists.length + 1;
            if (this.$psTeamStore.state.selectedAssetList.id === undefined) {
                this.newAssetList = "Liste" + " " + listAmount;
                this.addNewAssetList();
                setTimeout(() => {
                    if (image !== undefined) {
                        this.addAssetImage();
                    } else {
                        this.addAssetVideo();
                    }
                }, 1000);
            } else {
                if (image !== undefined) {
                    this.addAssetImage();
                } else {
                    this.addAssetVideo();
                }
            }
        },
        addAssetImage() {
            var name = moment(new Date()).format("DD-MM-YYYY-HH-mm-s-S")
            this.$apollo.mutate({
                mutation: addAssetMutation,
                variables: {
                    assetList: this.$psTeamStore.state.selectedAssetList.id,
                    name: name,
                    type: 'image'
                },
            })
                .then(({data}) => {
                    this.error = '';
                    this.captureScreen(data.addAsset, data.addAsset.id, data.addAsset.type);
                    this.updateData();
                }).catch((error) => {
                this.error = error.message;
            });
        },
        addAssetVideo() {
            var name = moment(new Date()).format("DD-MM-YYYY-HH-mm-s-S")
            this.$apollo.mutate({
                mutation: addAssetMutation,
                variables: {
                    assetList: this.$psTeamStore.state.selectedAssetList.id,
                    name: name,
                    type: 'video'
                },
            })
                .then(({data}) => {
                    this.error = '';
                    this.$psTeamStore.state.activeRecording = data.addAsset;
                    this.captureScreen(data.addAsset, data.addAsset.id, data.addAsset.type);
                    this.updateData();
                    this.recording = true;
                    var that = this
                    this.downloadTimer = setInterval(function(){
                        that.countdown--;
                        document.getElementById("countdowntimer").textContent = that.countdown;
                        if(that.countdown <= 0)
                            clearInterval(this.downloadTimer);
                    },1000);
                    setTimeout(() => {
                        if(that.countdown === 0) {
                            that.recording = false;
                            that.stopRecording()
                        }
                    }, 90000);
                }).catch((error) => {
                this.error = error.message;
            });
        },
        stopRecording() {
            this.$apollo.mutate({
                mutation: stopAssetMutation,
                variables: {
                    asset: this.$psTeamStore.state.activeRecording.id,
                },
            })
                .then(({data}) => {
                    this.error = '';
                    this.recording = false
                    clearInterval(this.downloadTimer);
                    this.countdown = 90
                    this.editAssetFile(this.$psTeamStore.state.activeRecording)
                }).catch((error) => {
                this.error = error.message;
            });
        },
        b64toBlob(b64Data, contentType, sliceSize) {
            // Thank you https://ourcodeworld.com/articles/read/322/how-to-convert-a-base64-image-into-a-image-file-and-upload-it-with-an-asynchronous-form-using-jquery
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
        captureScreen(asset, assetId, assetType) {
            document.getElementById("camera-shutter").className += "shutter-class";
            setTimeout(() => {
                document.getElementById("camera-shutter").classList.remove("shutter-class");
            }, 250);

            function findFirstDescendant(parent, tagname)
            {
                parent = document.getElementById(parent);
                var descendants = parent.getElementsByTagName(tagname);
                if ( descendants.length )
                    return descendants[0];
                return null;
            }
            var image = findFirstDescendant("remote-media-div", "video");
            var imgCanvas = document.createElement("canvas"),
                imgContext = imgCanvas.getContext("2d");

            imgCanvas.width = image.offsetWidth;
            imgCanvas.height = image.offsetHeight;

            imgContext.drawImage(image, 0, 0, imgCanvas.width, imgCanvas.height);

            var imgAsDataURL = imgCanvas.toDataURL("image/png");

            try {
                localStorage.setItem(assetId, imgAsDataURL);
            }
            catch (e) {
                console.log("Storage failed: " + e);
            }
            this.getThumbnail(assetId);

            var block = imgAsDataURL.split(";");
            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            var imgBlob = this.b64toBlob(realData, contentType);

            var formData = new FormData();
            formData.append('data', imgBlob, assetId + '.png');
            const token = localStorage.getItem('JWT');
            this.$http.post(BACKEND_URI + "/asset/" + assetId + "/file", formData, {headers: {'Authorization': token ? `Bearer ${token}` : null}}).then((response) => {
                if (response.status === 404) {
                    this.error = this.$t('image.error');
                } else {
                    if (assetType === "image") {
                        this.editAssetFile(asset);
                    }
                    this.getSingleAsset(assetId);
                }
            });
        },
        getStorage(){
            var assetId;
            var that = this;
            this.$psTeamStore.state.selectedSession.assetLists.forEach(function(list) {
                list.assets.forEach(function(asset){
                    assetId = asset.id;
                    that.getThumbnail(assetId);
                });
            });
        },
        getThumbnail(assetId){
            var value = localStorage.getItem(assetId);
            var key = assetId;
            var image = [];
            image.push(key, value);
            this.thumbData.push(image);
            this.$psTeamStore.state.thumbData = this.thumbData;
        },
        getSingleAsset(asset) {
            const token = localStorage.getItem('JWT');
            var options = {
                url: BACKEND_URI + "/asset/"  + asset + "/file",
                method: 'GET',
                responseType:'blob',
                headers:
                    {
                        Authorization: token ? `Bearer ${token}` : null
                    }
            }
            this.$http(options).then((response) => {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(response.data);
                document.getElementById(asset).src = imageUrl;
                this.removeFromStorage(asset);
            });
        },
        getAssets() {
            var that = this;
            if (this.allSessions !== null) {
                this.selectedS.assetLists.forEach(function (list) {
                    list.assets.forEach(function (asset) {
                        that.getSingleAsset(asset.id);
                    });
                });
            }
        },
        removeFromStorage(asset) {
            localStorage.removeItem(asset);
        },
        editAssetFile(asset) {
            this.$psTeamStore.state.selectedAsset = asset;
            this.editAssetModal = true;
        },
        downloadAssets(allSessions) {
            this.downloadStarted = true;
            const token = localStorage.getItem('JWT');
            var options = {
                url: BACKEND_URI + "/assets/" + allSessions.id,
                method: 'GET',
                responseType:'arraybuffer',
                headers:
                    {
                        Authorization: token ? `Bearer ${token}` : null
                    }
            }
            this.$http(options).then((response) => {
                var blob = new Blob([response.data], { type:   'zip' } );
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = window.URL.createObjectURL(blob);
                link.download = allSessions.reference + '.zip';
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
                this.downloadStarted = false;
            }).catch((error) => {
                var that = this;
                this.downloadStarted = false;
                if (error.status === 404) {
                    this.downloadError = true;
                    setTimeout(function () {
                        that.downloadError = false
                    }, 5000)
                }
            });
        },
        downloadProtocol(allSessions) {
            const token = localStorage.getItem('JWT');
            var options = {
                url: BACKEND_URI + "/protocol/" + allSessions.id,
                method: 'GET',
                responseType:'arraybuffer',
                headers: {
                    Authorization: token ? `Bearer ${token}` : null
                }
            }
            this.$http(options).then((response) => {
                if (response.status !== 200) {
                    this.error = this.$t('download.error');
                } else {
                    console.log(response);
                    var blob = new Blob([response.data], { type:   'xml' } );
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Protokoll.xml';
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                }
            });
        },
        downloadPDF(allSessions) {
            const token = localStorage.getItem('JWT');
            var options = {
                url: BACKEND_URI + "/pdf/" + allSessions.id,
                method: 'GET',
                responseType:'arraybuffer',
                headers: {
                    Authorization: token ? `Bearer ${token}` : null
                },
                params : {
                    locale: i18n.locale
                }
            }
            this.$http(options).then((response) => {
                if (response.status !== 200) {
                    this.error = this.$t('download.error');
                } else {
                    console.log(response);
                    var blob = new Blob([response.data], { type:   'pdf' } );
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Protokoll.pdf';
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                }
            });
        },
        updateData() {
            this.$apollo.queries.allSessions.refetch()
                .then(() => {
                    var sessionId = this.$psTeamStore.state.selectedSession.id;
                    var result = this.allSessions.filter(function (obj) {
                        return obj.id == sessionId;
                    })[0];
                    this.$psTeamStore.state.selectedSession = result;
                }).catch((error) => {
                this.error = error.message;
            });
        },
        endCall() {
            this.$apollo.queries.allSessions.refetch();
            this.selectedS = null;
            this.twilioToken = null;
            this.ListView = true;
            location.reload();
            this.checkFiles();
        },
        markAsDone(allSessions) {
            this.$apollo.mutate({
                mutation: marksAsDoneMutation,
                variables: {
                    session: allSessions.id
                },
            })
                .then(({data}) => {
                    var name;
                    var that = this;
                    allSessions.assetLists.forEach(function(list) {
                        list.assets.forEach(function(asset){
                            console.log(asset.name);
                            localStorage.removeItem(asset.name);
                        });
                    });
                    this.endSessionModal = false;
                    this.$apollo.queries.allSessions.refetch();
                }).catch((error) => {
                this.error = error.message;
            });
        },
        removeSession(allSessions) {
            this.$apollo.mutate({
                mutation: deleteSessionMutation,
                variables: {
                    session: allSessions.id
                },
            })
                .then(({data}) => {
                    this.error = '';
                    this.deleteSessionModal = false;
                    this.$apollo.queries.allSessions.refetch();
                }).catch((error) => {
                this.error = error.message;
            });
        },
        setSessionFilter(filter) {
            if (this.orderedBy.value === filter) {
                this.orderedBy.reversed = !this.orderedBy.reversed
            } else {
                this.orderedBy.value = filter
                this.orderedBy.reversed = false
            }
        },
        resetFilters() {
          console.log('lol');
          this.selectedReferences = [];
          this.selectedCategories = [];
          this.selectedStatus = [];
          this.selectedDates = {
            from: null,
            to: null
          };
        }
    }
}
