import gql from 'graphql-tag';

const createSessionMutation = gql`
mutation ($dateTime: String!, $reference: String!, $category: String!, $firstname: String!, $lastname: String!, $company: String!, $email: String!,){
  createSession(session: {dateTime: $dateTime, reference: $reference, category: $category},
    attendant: {firstname: $firstname, lastname: $lastname, company: $company, email: $email}) {
    id
  }
}
`;

const addAttMutation = gql`
    mutation ($session: String!, $firstname: String!, $lastname: String!, $company: String!, $email: String!) {
        addAttendant (session: $session, attendant: {firstname: $firstname, lastname: $lastname, company: $company, email: $email}) {
          id
        }
    }
`;

const categoryQuery = gql`
  query category {
    allCategories {
      id
      name
    }
  }
`;

export default {
    data() {
        return {
            configDate: {
                format: 'DD.MM.YYYY',
                minDate: new Date()
            },
            configTime: {
                format: 'HH:mm'
            },
            create: {
                reference: '',
                category: '',
                company: '',
                firstname: '',
                lastname: '',
                email: ''
            },
            addAtt: {
                company: '',
                firstname: '',
                lastname: '',
                email: ''
            },
            allCategories: [],
            error: '',
            dateTime: new Date(),
            aTime: new Date(),
            aDate: new Date(),
            added: false,
            addAtts: false
        }
    },
    apollo: {
        allCategories: {
            query: categoryQuery,
        },
    },
    methods: {
        validateBeforeSubmit() {
            var timeString = this.aTime instanceof Date ? this.aTime.toLocaleString('de-DE', { hour: 'numeric', minute: 'numeric' }) : this.aTime;
            var dateString = this.aDate instanceof Date ? this.aDate.toLocaleString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' }) : this.aDate;
            var dateSplit = dateString.toString().split(".");
            var timeSplit = timeString.toString().split(":");
            this.dateTime = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0], timeSplit[0], timeSplit[1]);

            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.submit();
                }else {
                    this.error = 'Bitte alle Felder ausfüllen!';
                }
            });
        },
        removeAddAtt () {
            this.addAtt.company = ''
            this.addAtt.firstname = ''
            this.addAtt.lastname = ''
            this.addAtt.email = ''
            this.addAtts = false
        },
        submit() {
            if (this.dateTime > new Date()) {
                this.$apollo.mutate({
                    mutation: createSessionMutation,
                    variables: {
                        dateTime: this.dateTime,
                        reference: this.create.reference,
                        category: this.create.category,
                        firstname: this.create.firstname,
                        lastname: this.create.lastname,
                        company: this.create.company,
                        email: this.create.email
                    },
                })
                .then(({data}) => {
                    if (this.addAtt.company !== null || undefined) {
                        console.log('company:' + this.addAtt.company);
                        this.$apollo.mutate({
                            mutation: addAttMutation,
                            variables: {
                                session: data.createSession.id,
                                company: this.addAtt.company,
                                firstname: this.addAtt.firstname,
                                lastname: this.addAtt.lastname,
                                email: this.addAtt.email
                            },
                        })
                    }
                    this.error = '';
                    this.added = true;
                }).catch((error) => {
                    this.error = error.message;
                });
            } else {
                this.error = 'Das Datum muss in der Zukunft liegen!';
            }
        }
    }
}
