import Vue from 'vue';
import App from './components/App.vue';
import VueResource from 'vue-resource'
import store from './jsComponents/store.js'
import moment from 'moment'
import jquery from 'jquery'
import datePicker from 'vue-bootstrap-datetimepicker'
import BootstrapVue from 'bootstrap-vue'
import storePlugin from './jsComponents/storePlugin'
import underscore from 'underscore'
import VueBrowserUpdate from 'vue-browserupdate'
import * as Sentry from '@sentry/browser';
import VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import router from './router'
import i18n from './i18n'
import apolloProvider from './apollo'

Sentry.init({
    dsn: process.env.SENTRY_DSN || "",
    environment: process.env.SENTRY_ENVIRONMENT || ""
});

Vue.use(BootstrapVue);
Vue.use(storePlugin);
Vue.use(VueResource);
Vue.use(datePicker);

Vue.use(VeeValidate, {
    locale: i18n.locale,
    dictionary: i18n.messages
});

Vue.use(VueBrowserUpdate, {
    options: {
        notify:{i:-0.01,f:-0.01,o:-0.01,s:-0.01,c:-0.01},
        container: document.body,
        text: "Ihr Browser {brow_name} ist veraltet, bitte aktualisieren Sie den Browser, um eine volle Funktionaliät der Anwendung zu gewährleisten. <a{ignore_but}>Ignorieren</a>",
    }
});

window._ = require('underscore');

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm ')
    }
});

const app = new Vue({
    el: '#app',
    i18n,
    apolloProvider,
    router: router,
    render: h => h(App),
    data: {}
});
